<template>
  <div>
  <v-btn plain to="/gasto"><v-icon left>mdi-arrow-left-circle</v-icon>Volver</v-btn>
  <v-container fluid>
    <v-row>
    <v-spacer></v-spacer>
    </v-row>
    <br>
    <v-row>
    <v-card class="mx-auto" width="1100" tile>
      bodega
      <v-data-table
        :headers="headers" 
        :items="informes" 
        disable-pagination 
        :hide-default-footer="true"></v-data-table>
    </v-card>
    </v-row>
  </v-container>
  <semaDialog ref="newinf"></semaDialog>
  </div>
</template>

<script>
import semaDlg from './newinf'; 
export default {
  name:'infsema',
  components: {
    semaDialog: semaDlg,
  },
  data () {
    return {
      informes:[],
      headers: [
        { text: "Informe", value: "", align: "start", sortable: false},
        { text: "ID SAP", value: "", sortable: false },
        { text: "F. Informe", value: "", sortable: false },
        { text: "F. Recepción", value: "", sortable: false },
        { text: "Proveedor", value: "", align:"center", sortable: false },
        { text: "Detalle", value: "", align:"center", sortable: false },
        { text: "$ Costo", value: "", align:"center", sortable: false },
        { text: "Área", value: "", align:"center", sortable: false },
        { text: "Acción", value: "actions", align:"center", sortable: false }
        
      ]
    }
  },
  methods: {
    async newinfsema(id) {
        this.id = id
        await this.$refs.newinf.open()
      // this.$router.push({ name: "code-details", params: { id: id } });
    },
  },
  mounted() {
    
  }
  
}
</script>